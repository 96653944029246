<template>
	<div>
		<div style="display: inline-block; width: 100%; height: 100%">
			<div class="child-top" style="padding-top: 0">
				<div class="input-from">
					<el-select clearable placeholder="请选择角色" v-model="releSelects" @change="releSelect()">
						<el-option v-for="(val, key, index) in releSelectsArr" :key="index" :label="val" :value="key"></el-option>
					</el-select>
					<el-select class="last-child" clearable placeholder="请选择状态" v-model="roleState" @change="stateChange()">
						<el-option :label="'启用'" :value="1"></el-option>
						<el-option :label="'停用'" :value="0"></el-option>
					</el-select>

					<el-input placeholder="请输入关键字" style="width: 200px; float: left" @keyup.enter.native="getData(true)" suffix-icon="iconfont iconsousuo" v-model="input4"> </el-input>
					<el-button class="searchBtn" round @click="getData(true)">搜索</el-button>
				</div>

				<div class="operation-button">
					<el-button @click="addDataLog" class="new_btn" :disabled="$store.getters.permissionsStr('新增')" round>
						<span>新增</span>
					</el-button>
					<el-button @click="delMoreData" class="del_btn" round :disabled="$store.getters.permissionsStr('删除')">
						<span>删除</span>
					</el-button>
				</div>
			</div>
			<div class="content-flex-pages" style="background: #fff">
				<el-table
					:empty-text="tableData.length ? '' : '暂无数据'"
					:data="!$store.getters.permissionsStr('列表') ? tableData : []"
					@selection-change="tableSelectionChange"
					class="content-test-table"
					style="width: 100%"
					v-loading="tableLoading"
				>
					<el-table-column type="selection" width="45"></el-table-column>
					<el-table-column label="序号" type="index" width="45">
						<template slot-scope="scope">
							{{ (currentPage - 1) * 10 + scope.$index + 1 }}
						</template>
					</el-table-column>
					<el-table-column label="账号" prop="account" show-overflow-tooltip></el-table-column>
					<el-table-column label="姓名" prop="name" show-overflow-tooltip></el-table-column>
					<!-- <el-table-column label="账号有效期" prop="js_period" show-overflow-tooltip></el-table-column> -->
					<el-table-column label="创建人" prop="create_by" show-overflow-tooltip></el-table-column>
					<el-table-column label="创建时间" prop="create_time" show-overflow-tooltip></el-table-column>
					<el-table-column label="备注" prop="demo" show-overflow-tooltip></el-table-column>
					<el-table-column label="状态" prop="status" show-overflow-tooltip width="80">
						<template slot-scope="scope">
							<el-switch
								v-show="scope.row.uuid"
								active-color="#0055E9"
								:active-value="1"
								:inactive-value="0"
								:width="35"
								@change="ChangeState(scope.row)"
								inactive-color="#ccc"
								v-model="scope.row.status"
								:disabled="$store.getters.permissionsStr('启/停用')"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<!--                            v-show="!$store.getters.permissionsStr('查看')"-->
							<el-button
								class="right20"
								style="color: #0055e9"
								size="medium"
								type="text"
								@click="editorData(scope.row, scope.$index)"
								:disabled="$store.getters.permissionsStr('编辑')"
								v-if="scope.row.uuid"
								>编辑
							</el-button>
							<el-button
								class="right20"
								style="color: #ebb400"
								size="medium"
								type="text"
								@click="restoreData(scope.row, scope.$index)"
								:disabled="$store.getters.permissionsStr('还原密码')"
								v-if="scope.row.uuid"
								>还原密码
							</el-button>
							<!-- #F5A623 -->
							<el-button
								@click="delTableData(scope.row)"
								class="redColor"
								size="medium"
								style="color: #fd3258"
								type="text"
								:disabled="$store.getters.permissionsStr('删除')"
								v-if="scope.row.uuid"
								>删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					:current-page.sync="currentPage"
					:page-size="10"
					:total="tableTotal"
					@current-change="handleCurrentChange"
					class="table-pagination"
					layout="total, prev, pager, next , jumper"
				></el-pagination>
			</div>
		</div>

		<!--  新增-->
		<el-dialog
			:close-on-click-modal="false"
			:show-close="false"
			:visible.sync="dialogTableVisible"
			v-loading="addtableLoading"
			:title="!dialogTableVisibleType ? '新增账号' : '编辑账号'"
			width="50%"
			center
		>
			<div class="ModifyDoalog">
				<div class="newTins">
					<div class="newLeft">
						<el-form :label-position="'left'" :rules="rules" :model="form" label-width="70px" ref="form">
							<el-form-item label="账号" prop="zh_account">
								<el-input v-model="form.zh_account" placeholder="请输入账号"></el-input>
							</el-form-item>
							<el-form-item label="密码" prop="zh_password" v-if="dialogTableVisibleType == false">
								<el-input v-model="form.zh_password" placeholder="请输入密码"></el-input>
							</el-form-item>
							<el-form-item label="姓名" prop="zh_name">
								<el-input v-model="form.zh_name" placeholder="请输入姓名"></el-input>
							</el-form-item>
							<el-form-item label="所属运动队" prop="zh_branch">
								<el-cascader v-model="form.zh_branch" :options="zhBranchArr" :show-all-levels="false" :props="{ value: 'uuid', label: 'name', emitPath: false }"></el-cascader>
							</el-form-item>
							<!-- <el-form-item label="email" prop="zh_email">
                <el-input v-model="form.zh_email"></el-input>
              </el-form-item> -->
							<el-form-item label="手机号" prop="zh_phone">
								<el-input v-model="form.zh_phone"></el-input>
							</el-form-item>
							<el-form-item label="岗位" prop="zh_post">
								<el-select v-model="form.zh_post" placeholder="请选择">
									<el-option v-for="item in zhPostArr" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="备注" prop="zh_remark">
								<el-input v-model="form.zh_remark"></el-input>
							</el-form-item>
							<el-form-item label="账号有效期" prop="date">
								<el-date-picker
									v-model="form.date"
									type="daterange"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									clearable
									style="width: 100%"
								>
								</el-date-picker>
							</el-form-item>
						</el-form>
					</div>
					<div class="newRight">
						<div style="width: 45%; float: left; border: 1px solid #ccc">
							<span style="margin: 0px 10px 0 20px"> 选择角色 </span>
							<el-input
								class="left-child-input left-child-input-content"
								clearable
								placeholder="请输入关键字"
								style="width: 200px; margin-bottom: 15px; margin-top: 10px"
								suffix-icon="iconfont iconsousuo"
								v-model="search"
								@keyup.enter.native="roleGetData(true)"
							>
							</el-input>
							<!-- @selection-change="newTableSelectionChange" -->

							<el-table
								:data="options"
								@select="rowChange"
								@select-all="rowChangeAll"
								class="content-test-table new_table"
								v-loading="addtableLoading"
								style="width: 100%"
								ref="dataTable"
								height="250"
								:header-cell-style="{ background: '#F4F6F9' }"
							>
								<el-table-column type="selection" width="45"></el-table-column>
								<el-table-column label="序号" type="index" width="45">
									<template slot-scope="scope">
										{{ (currentPage - 1) * 10 + scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column label="姓名" prop="name" width="220" show-overflow-tooltip></el-table-column>
							</el-table>
						</div>

						<div style="width: 50%; marin-left: 50px; float: right; border: 1px solid #ccc">
							<span style="display: block; margin: 15px 10px 28px 20px"> 已选角色 </span>
							<el-table
								:data="selectTableData"
								class="content-test-table new_table"
								style="width: 100%"
								height="250"
								v-loading="addtableLoading"
								:header-cell-style="{ background: '#F4F6F9' }"
							>
								<el-table-column label="序号" type="index" width="45">
									<template slot-scope="scope">
										{{ (currentPage - 1) * 10 + scope.$index + 1 }}
									</template>
								</el-table-column>
								<el-table-column label="姓名" width="250" prop="name" show-overflow-tooltip></el-table-column>
								<el-table-column width="50">
									<template slot-scope="scope">
										<i class="iconfont iconshanchu-moren" @click="selectClick(scope.$index)"></i>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
				<div class="form-bottom-button">
					<el-button @click="save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
					<el-button @click="cancel" class="cancel_btn" round>取消</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			input4: '',
			roleState: '',
			releSelects: '',
			releSelectsArr: {},
			tableDataSelectArr: [], // 多选
			newTableDataSelectArr: [], // 新增 多选
			tableData: [{ uuid: 0 }], // 表格
			form: {
				date: '',
				id: '',
				zh_account: '',
				zh_password: '',
				zh_name: '',
				zh_branch: '', // 所属运动队
				zh_email: '',
				zh_phone: '',
				zh_post: '', // 所属岗位
				zh_remark: '',
				uuid: '',
				accountNum: '',
			},
			zhBranchArr: [],
			zhPostArr: [],
			rules: {
				zh_account: { ...this.$rules.noEmpty, message: '账号不能为空' },
				zh_password: { ...this.$rules.noEmpty, message: '密码不能为空' },
				zh_name: { ...this.$rules.noEmpty, message: '名称不能为空' },
			},
			currentPage: 1,
			tableTotal: 5,
			total: '',
			tableLoading: false,
			addtableLoading: false,
			dialogTableVisible: false,
			dialogTableVisibleType: false,
			newTableData: [],
			checkBoxArr: [], // 新增账户选择
			options: [],
			onselect: [],
			selectTableData: [],
			search: '',
		}
	},
	computed: {
		add() {
			var search = this.input4
			if (search) {
				return this.tableData.filter(data => {
					if (data.name.includes(search)) {
						return data
					}
				})
			}
			return this.tableData
		},
	},
	watch: {
		input4() {
			var search = this.input4
			let arr = []
			if (search) {
				this.tableData.some(data => {
					let type = data.name.includes(search)
					if (type) {
						// arr.push(data)
					}
				})
			}
			// return this.tableData;
		},
	},
	mounted() {
		this.create()
		// this.setScroll()
	},
	methods: {
		filterChange(row) {},
		create(page) {
			this.tableLoading = true
			if (page) {
				this.currentPage = page
			} else {
				this.currentPage = 1
			}
			// 列表数据
			this.$axios
				.post(
					'/p/user/lists',
					this.$qs({
						page: this.currentPage,
						keyword: this.input4,
						status: this.roleState !== '' ? this.roleState : '3',
						role_uuid: this.releSelects,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						// this.tableData = res.data.data.lists;
						this.$set(this, 'tableData', res.data.data.lists)
						this.tableTotal = res.data.data.total
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
					this.tableLoading = false
				})
				.catch(error => {
					console.log(error)
				})
			// 搜索角色
			this.$axios
				.post('/p/role/getAll')
				.then(res => {
					if (res.data.code === 0) {
						this.releSelectsArr = res.data.data
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 所属岗位接口
		finash() {
			this.$axios
				.post('/p/station/show')
				.then(res => {
					if (res.data.code === 0) {
						this.zhPostArr = res.data.data
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
			// 运动队接口
			this.$axios
				.post('/p/staff/tree')
				.then(res => {
					if (res.data.code === 0) {
						this.zhBranchArr = res.data.data.department
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 列表搜索
		getData() {
			this.create()
		},
		// 新增 多选
		newTableSelectionChange(selection, row) {
			// this.selectTableData = val
		},
		selectClick(index) {
			this.$nextTick(() => {
				var clear = this.selectTableData.splice(index, 1)[0]
				this.options.forEach((item, index) => {
					if (item.uuid == clear.uuid) {
						this.$refs.dataTable.toggleRowSelection(item, false)
					}
				})
			})
		},
		rowChange(selection, row) {
			var typeLink = false,
				idx = -1
			this.selectTableData &&
				this.selectTableData.forEach((item, index) => {
					if (item.uuid == row.uuid) {
						typeLink = true
						idx = index
					}
				})

			setTimeout(() => {
				if (!typeLink) {
					this.selectTableData.push(row)
				} else {
					this.selectTableData.splice(idx, 1)
				}
			}, 100)
		},

		rowChangeAll(selection) {
			if (selection.length) {
				selection.forEach(item => {
					let index = JSON.stringify(this.selectTableData).indexOf(JSON.stringify(item))
					if (index == -1) {
						this.selectTableData.push(item)
					}
				})
			} else {
				this.selectTableData = []
			}
		},

		// 账户搜索
		roleGetData() {
			// 搜索角色
			this.$axios
				.post(
					'/p/role/getAll',
					this.$qs({
						keyword: this.search,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						var data = res.data.data
						// this.onselect = [];
						this.options = []
						let index = 0
						for (var i in data) {
							var obj = {
								name: data[i],
								uuid: i,
								can_check: false,
								checked: false,
							}
							// this.options.push(obj);
							this.$set(this.options, index, obj)
							index++
						}

						this.$nextTick(() => {
							this.options.forEach(item => {
								this.selectTableData.forEach((itm, idx) => {
									if (itm.uuid == item.uuid) {
										this.$refs.dataTable.toggleRowSelection(item, true)
									}
								})
							})
						})
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 新增
		addDataLog() {
			this.addtableLoading = true
			this.dialogTableVisible = true
			this.dialogTableVisibleType = false
			this.finash()
			this.roleGetall()
			this.form.id = ''
		},
		// 取消
		cancel() {
			this.dialogTableVisible = false
			this.$nextTick(() => {
				this.$refs.form.resetFields()
				// this.$refs.remark.resetFields();
				this.form.accountNum = ''
				this.selectTableData = []
			})
		},
		// 保存
		save() {
			this.$refs.form.validate(valid => {
				if (valid) {
					let uuids = ''
					this.selectTableData.forEach((item, index) => {
						if (index == 0) {
							uuids = item.uuid
						} else {
							uuids += ',' + item.uuid
						}
					})
					if (!this.$sishiLength(this.form.zh_account)) {
						return this.$message({
							type: 'error',
							message: '账号最大长度为40',
						})
					}

					if (!this.$sishiLength(this.form.zh_name)) {
						return this.$message({
							type: 'error',
							message: '姓名最大长度为40',
						})
					}

					if (!this.$bashiLength(this.form.zh_remark)) {
						return this.$message({
							type: 'error',
							message: '备注最大长度为80',
						})
					}
					var data = {
						account: this.form.zh_account,
						pwd: this.form.zh_password,
						name: this.form.zh_name,
						mobile: this.form.zh_phone,
						department_uuid: this.form.zh_branch,
						station_uuid: this.form.zh_post,
						demo: this.form.zh_remark,
						start_date: this.form.date ? this.form.date[0] : '',
						end_date: this.form.date ? this.form.date[1] : '',
						role_uuids: uuids,
					}
					if (this.form.id !== '') {
						data.uuid = this.form.id
					}
					this.$axios
						.post(this.form.id !== '' ? '/p/user/update' : '/p/user/add', this.$qs(data))
						.then(res => {
							if (res.data.code === 0) {
								this.dialogTableVisible = false
								this.create()
								this.$message({
									message: res.data.message,
									type: 'success',
								})
								this.$nextTick(() => {
									this.$refs.form.resetFields()
									this.selectTableData = []
									// this.$refs.remark.resetFields();
								})
							} else {
								this.$utils.message({
									type: 'error',
									message: res.data.message,
								})
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					return false
				}
			})
		},
		// 获取账户
		roleGetall() {
			// 搜索角色
			this.$axios
				.post('/p/role/getAll')
				.then(res => {
					if (res.data.code === 0) {
						var data = res.data.data
						this.options = []
						for (var i in data) {
							var obj = {
								name: data[i],
								uuid: i,
								can_check: false,
								checked: false,
							}
							this.options.push(obj)
						}
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
					this.addtableLoading = false
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 编辑
		editorData(row, index) {
			this.addtableLoading = true
			this.dialogTableVisible = true
			this.dialogTableVisibleType = true
			this.finash()
			// this.options = [];
			// 获取单条数据
			this.$axios
				.post(
					'/p/user/read',
					this.$qs({
						uuid: row.uuid,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.dialogTableVisibleType = true
						var data = res.data.data
						this.form.id = data.uuid
						this.form.zh_account = data.account
						// this.form.zh_password = data.account;
						this.form.zh_name = data.name
						this.form.zh_branch = data.department_uuid
						this.form.zh_email = data.email
						this.form.zh_phone = data.mobile
						this.form.zh_post = data.station_uuid
						this.form.zh_remark = data.demo
						if (data.start_date) {
							this.form.date = [data.start_date, data.end_date]
							if (data.start_date == '0000-00-00' && data.end_date === '0000-00-00') {
								this.form.date = ['', '']
							}
						} else {
							this.form.date = ['', '']
						}
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
			this.editRoleGetall(row.uuid)
			// this.roleGetall();
		},
		// 编辑获取账户
		editRoleGetall(uuid) {
			this.$axios
				.post(
					'/p/role/getAll',
					this.$qs({
						user_uuid: uuid,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						var data = res.data.data
						this.options = []
						// for (var i in data) {
						//   var obj = {
						//     name: data[i].name,
						//     uuid: data[i].uuid,
						//     can_check: data[i].can_check ? false : true,
						//     checked: data[i].checked ? true : false
						//   };
						//   this.onselect.push(obj);
						// }

						for (var val in res.data.data) {
							this.options.push(res.data.data[val])
						}
						this.$nextTick(() => {
							this.options.forEach(item => {
								if (item.checked) {
									this.selectTableData.push(item)
									this.$refs.dataTable.toggleRowSelection(item, true)
								}
							})
						})

						// this.onselect.forEach((item, index) => {
						//   this.options.forEach((val, idx) => {
						//     if (item.uuid === val.uuid) {
						//       this.options.splice(idx, 1, item);
						//     }
						//   });
						// });
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
					this.addtableLoading = false
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 还原密码
		restoreData(row, index) {
			this.$confirm('确定还原密码?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/user/resetPwd',
						this.$qs({
							uuid: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.create(this.currentPage)
							this.$message({
								message: res.data.message,
								type: 'success',
							})
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		},
		// 单行删除
		delTableData(row) {
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/user/del',
						this.$qs({
							uuids: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							let num = this.getNum(this.tableTotal - 1)
							if (this.currentPage > num) {
								this.currentPage = num
							}
							this.create(this.currentPage)
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		},
		// 多行删除
		delMoreData(row) {
			let touristIds = ''
			for (let i = 0; i < this.tableDataSelectArr.length; i++) {
				if (this.tableDataSelectArr.length - 1 == i) {
					touristIds += `${this.tableDataSelectArr[i].uuid}`
				} else {
					touristIds += `${this.tableDataSelectArr[i].uuid},`
				}
			}
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/user/del',
						this.$qs({
							uuids: touristIds,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							let num = this.getNum(this.tableDataSelectArr.length - 1)
							if (this.currentPage > num) {
								this.currentPage = num
							}
							this.create(this.currentPage)
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		},
		// 选择状态  1 启用 2 停用  空
		stateChange() {
			this.create()
		},
		// 表格状态 0 禁用 1 启用
		ChangeState(state) {
			this.$axios
				.post(
					'/p/user/able',
					this.$qs({
						uuid: state.uuid,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.create(this.currentPage)
						this.$message({
							message: res.data.message,
							type: 'success',
						})
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		// 多选
		tableSelectionChange(val) {
			this.tableDataSelectArr = val
		},
		// 角色选择
		releSelect() {
			// 列表数据
			this.$axios
				.post(
					'/p/user/lists',
					this.$qs({
						page: 1,
						page_size: 10,
						keyword: '',
						status: this.roleState !== '' ? this.roleState : '3',
						role_uuid: this.releSelects,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.tableData = res.data.data.lists
						this.tableTotal = res.data.data.total
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.create(val)
			this.tableLoading = true
		},
		// 查看当前页数
		getNum(data) {
			var num = data / 10,
				pages = ''
			if (num > Math.floor(num)) {
				pages = Math.ceil(num)
			} else {
				pages = num
			}
			return pages
		},
	},
}
</script>

<style lang="scss" scoped>
.child-top {
	.input-from {
		float: left;
		min-width: 750px;
		margin-bottom: 0;

		.searchBtn {
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 20px;

			.el-input__inner {
				border-radius: 0;
				border-right: transparent;
			}

			.el-input__inner:focus {
				border-right: 1px solid #409eff;
			}
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-select:nth-child(4) {
			.el-input__inner:focus {
				border-right: 0.052083rem solid #409eff;
			}
		}

		.el-select:nth-child(5) {
			.el-input__inner {
				border-right: 0;
			}

			.el-input__inner:focus {
				border-right: 0.052083rem solid #409eff;
			}
		}

		.el-select:nth-child(6) {
			width: 120px;
			margin-left: 20px;

			.el-input__inner {
				border-radius: 0px 4px 4px 0;
			}
		}

		.el-select:nth-child(1) {
			.el-input__inner {
				border-radius: 4px 0 0 4px;
			}
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;

			.el-input__inner {
				border-radius: 0 4px 4px 0;
			}
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;
	}
}
.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;
	max-height: 560px;
	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}
	.el-form {
		padding-right: 30px;
	}
	.newTins {
		height: 90%;
		overflow: auto;
		max-height: 500px;
	}
	.newLeft {
		width: 100%;
		height: auto;
		display: inline-block;
		.el-form-item:nth-child(odd) {
			width: 48%;
			float: left;
		}
		.el-form-item:nth-child(even) {
			width: 48%;
			float: right;
		}
		/deep/ .el-form-item__content {
			.el-input {
				.el-input__inner {
					border: none;
					border-radius: 0;
					border-bottom: 2px solid #ccc;
					&:focus {
						border-color: #0055e9;
					}
				}
			}
		}
	}
	.newRight {
		width: 100%;
		height: 100%;
		float: right;
		.el-form {
			.el-form-item:last-child {
				// height: 230px;
				/deep/ .el-form-item__content {
					height: 100%;
				}
			}
		}
	}
}

/deep/ .el-form-item__content {
	.el-input {
		.el-input__inner {
			border: none;
			border-radius: 0;
			border-bottom: 2px solid #ccc;
			&:focus {
				border-color: #0055e9;
			}
		}
	}
}
.el-row {
	height: 100%;
	border: 1px solid #dcdfe6;
	border-radius: 4px;
	overflow-y: auto;
	&:last-child {
		margin-bottom: 0;
	}
}
.el-col {
	border-radius: 4px;
	.grid-content {
		padding: 0 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.toHeight {
	height: 169px;
	overflow: auto;
}

.ofHeight {
	height: 230px;
	overflow: auto;
}

.dialogFormChecked {
	width: 50%;
	float: left;
	margin-right: 0;
	padding-left: 14px;
	box-sizing: border-box;
	span {
		margin-left: 4px;
	}
}

.table-pagination {
	text-align: right;
	margin-top: 10px;
	/deep/ span {
		float: left;
	}
}
.el-cascader {
	width: 100%;
}
.el-select {
	width: 100%;
}
/deep/ input::-webkit-input-placeholder {
	color: #999;
}
/deep/ input::-moz-input-placeholder {
	color: #999;
}
/deep/ input::-ms-input-placeholder {
	color: #999;
}
.iconshanchu-moren {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}

.el-pagination {
	background: #fff;
	padding: 10px;
	padding-bottom: 10px;
	margin-top: 0;
}
</style>
